import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import Countdown, { CountdownRendererFn } from "react-countdown";
import { Translation } from "react-i18next";

const countdownRenderer: CountdownRendererFn = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}) => {
  if (completed) {
    return null;
  } else {
    return (
      <div className="flex gap-x-4 text-center">
        <div className="grow shrink basis-0">
          <span className="text-6xl block mb-2">
            {days.toString().padStart(2, "0")}
          </span>
          <Translation>{(t) => t("time.days")}</Translation>
        </div>
        <div className="grow shrink basis-0">
          <span className="text-6xl block mb-2">
            {hours.toString().padStart(2, "0")}
          </span>
          <Translation>{(t) => t("time.hours")}</Translation>
        </div>
        <div className="grow shrink basis-0">
          <span className="text-6xl block mb-2">
            {minutes.toString().padStart(2, "0")}
          </span>
          <Translation>{(t) => t("time.minutes")}</Translation>
        </div>
        <div className="grow shrink basis-0">
          <span className="text-6xl block mb-2">
            {seconds.toString().padStart(2, "0")}
          </span>
          <Translation>{(t) => t("time.seconds")}</Translation>
        </div>
      </div>
    );
  }
};

export const Invite: FC = () => {
  return (
    <div
      className="bg-cover bg-center relative lg:fixed lg:end-0 lg:start-1/2 lg:order-last"
      style={{ backgroundImage: "url(/images/bg-1.jpg)" }}
    >
      <img
        className="absolute top-0 start-0 max-w-none z-0"
        alt="Corner"
        src="/images/corner-gold-01.png"
        width="238"
      />

      <img
        className="absolute bottom-0 start-0 -rotate-90 max-w-none z-0 hidden md:block"
        alt="Corner"
        src="/images/corner-gold-01.png"
        width="238"
      />

      <img
        className="absolute top-0 end-0 max-w-none rotate-90 z-0 hidden lg:block"
        alt="Corner"
        src="/images/corner-gold-01.png"
        width="238"
      />

      <img
        className="absolute bottom-0 end-0 rotate-180 max-w-none z-0"
        alt="Corner"
        src="/images/corner-gold-01.png"
        width="238"
      />

      <div className="max-w-5xl	mx-auto">
        <div
          className="text-neutral-800"
          style={{ fontFamily: "'Playfair Display', serif" }}
        >
          <div className="relative h-screen min-h-max z-10 text-center flex flex-col justify-center items-center content-center">
            <div
              className="z-10 text-center flex flex-col justify-center items-center content-center relative"
              style={{ width: "95%", maxWidth: "600px" }}
            >
              <div
                style={{
                  maskImage: "url(/images/mask-01.png)",
                  maskSize: "contain",
                  maskPosition: "center center",
                  maskRepeat: "no-repeat",
                  WebkitMaskImage: "url(/images/mask-01.png)",
                  WebkitMaskSize: "contain",
                  WebkitMaskPosition: "center center",
                  WebkitMaskRepeat: "no-repeat",
                }}
              >
                <img src="/images/us.png" alt="us" />
              </div>
              <img
                className="absolute top-0 start-0"
                src="/images/mask-02.png"
                alt="mask"
              />
            </div>

            <Countdown
              date={new Date(2024, 5, 15, 15, 0, 0, 0)}
              renderer={countdownRenderer}
            />

            <FontAwesomeIcon
              icon={faChevronDown}
              className="text-4xl mt-8 opacity-50 animate-bounce lg:hidden"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
