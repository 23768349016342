import {
  Field,
  FieldArray,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";

interface Data {
  count: number;
  message: string;
  names: string[];
  dinner: string[];
  attendance: string[];
}

interface FormPayload {
  message: string;
  guests: {
    name: string;
    dinner: string;
    attendance: string;
  }[];
}

const FormSchema = Yup.object().shape({
  message: Yup.string().nullable(),
  guests: Yup.array().of(
    Yup.object({
      name: Yup.string().required("validation.required"),
      attendance: Yup.number().positive().required("validation.required"),
      dinner: Yup.number()
        .positive()
        .when("attendance", {
          is: (attendance: number) => attendance === 2 || attendance === 3,
          then: (schema) => schema.required("validation.required"),
          otherwise: (schema) => schema,
        }),
    })
  ),
});

export const PersonForm: FC<
  Pick<FormikProps<FormPayload>, "values" | "errors" | "touched"> & {
    index: number;
  }
> = ({ values, errors, touched, index }) => {
  const { t } = useTranslation();
  const error = errors.guests?.[index] as any;
  const touch = touched.guests?.[index];
  return (
    <>
      <div>
        <label
          htmlFor={`guests.${index}.name`}
          className={[
            "block",
            "mb-2",
            "text-sm",
            "font-medium",
            error?.name && touch?.name ? "text-red-600" : "text-gray-900",
          ].join(" ")}
        >
          {t("rsvp.name", { index: index + 1 })}{" "}
          <span className="text-red-500">*</span>
        </label>
        <Field
          id={`guests.${index}.name`}
          name={`guests.${index}.name`}
          className={[
            error?.name && touch?.name ? "bg-red-50" : "bg-gray-50",
            "border",
            error?.name && touch?.name ? "border-red-500" : "border-gray-300",
            "text-gray-900",
            "text-sm",
            "rounded-lg",
            "focus:ring-rose-500",
            "focus:border-rose-500",
            "block",
            "w-full",
            "p-2.5",
          ].join(" ")}
        />

        {error?.name && touch?.name ? (
          <div className="mt-2 text-sm text-red-600">{t(error?.name)}</div>
        ) : null}
      </div>
      <div>
        <label
          htmlFor={`guests.${index}.attendance`}
          className={[
            "block",
            "mb-2",
            "text-sm",
            "font-medium",
            error?.attendance && touch?.attendance
              ? "text-red-600"
              : "text-gray-900",
          ].join(" ")}
        >
          {t("rsvp.attending.name", { index: index + 1 })}{" "}
          <span className="text-red-500">*</span>
        </label>
        <Field
          as="select"
          id={`guests.${index}.attendance`}
          name={`guests.${index}.attendance`}
          className={[
            error?.attendance && touch?.attendance ? "bg-red-50" : "bg-gray-50",
            "border",
            error?.attendance && touch?.attendance
              ? "border-red-500"
              : "border-gray-300",
            "text-gray-900",
            "text-sm",
            "rounded-lg",
            "focus:ring-rose-500",
            "focus:border-rose-500",
            "block",
            "w-full",
            "p-2.5",
          ].join(" ")}
        >
          <option value="">{t("rsvp.select")}</option>
          <option value="1">{t("rsvp.attending.ceremony")}</option>
          <option value="3">
            {t("rsvp.attending.ceremonyAndCelebration")}
          </option>
          <option value="4">{t("rsvp.attending.unable")}</option>
        </Field>

        {error?.attendance && touch?.attendance ? (
          <div className="mt-2 text-sm text-red-600">
            {t(error?.attendance)}
          </div>
        ) : null}
      </div>
      <div>
        <label
          htmlFor={`guests.${index}.dinner`}
          className={[
            "block",
            "mb-2",
            "text-sm",
            "font-medium",
            error?.dinner && touch?.dinner ? "text-red-600" : "text-gray-900",
          ].join(" ")}
        >
          {t("rsvp.dinner.name", { index: index + 1 })}{" "}
          {(values.guests[index].attendance === "2" ||
            values.guests[index].attendance === "3") && (
            <span className="text-red-500">*</span>
          )}
        </label>
        <Field
          as="select"
          id={`guests.${index}.dinner`}
          name={`guests.${index}.dinner`}
          className={[
            error?.dinner && touch?.dinner ? "bg-red-50" : "bg-gray-50",
            "border",
            error?.dinner && touch?.dinner
              ? "border-red-500"
              : "border-gray-300",
            "text-gray-900",
            "text-sm",
            "rounded-lg",
            "focus:ring-rose-500",
            "focus:border-rose-500",
            "block",
            "w-full",
            "p-2.5",
          ].join(" ")}
        >
          <option value="">{t("rsvp.select")}</option>
          <option value="1">{t("rsvp.dinner.chicken")}</option>
          <option value="2">{t("rsvp.dinner.tofu")}</option>
          <option value="3">{t("rsvp.dinner.none")}</option>
        </Field>

        {error?.dinner && touch?.dinner ? (
          <div className="mt-2 text-sm text-red-600">{t(error?.dinner)}</div>
        ) : null}
      </div>
    </>
  );
};

export const RSVP: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [error, setError] = useState(undefined);
  const [invite, setInvite] = useState<FormPayload>({
    message: "",
    guests: [],
  });
  useEffect(() => {
    if (!id) {
      return;
    }
    fetch(`/api/${id}`)
      .then((res) => res.json())
      .then((res) => {
        if (res.message && res.error) {
          setError(res);
        } else {
          const data = res as Data;
          setInvite({
            message: data.message ?? "",
            guests: [...Array(data.count)].map((n, i) => ({
              name: data.names[i] ?? "",
              attendance: data.attendance[i] ?? "",
              dinner: data.dinner[i] ?? "",
            })),
          });
        }
      })
      .catch((err) => setError(err));
  }, [id]);

  if (!id || error) {
    return null;
  }

  return (
    <div className="max-w-3xl w-4/5 mx-auto py-4">
      <ToastContainer />
      <div className="flex items-center mb-6">
        <div className="flex-grow bg bg-gray-300 h-0.5"></div>
        <h2
          className="flex-grow-0 mx-5 text uppercase text-3xl lg:text-5xl"
          style={{ fontFamily: "'Playfair Display', serif" }}
        >
          {t("rsvp.title")}
        </h2>
        <div className="flex-grow bg bg-gray-300 h-0.5"></div>
      </div>
      <p className="mb-4">{t("rsvp.response")}</p>
      <Formik
        initialValues={invite}
        enableReinitialize={true}
        validationSchema={FormSchema}
        onSubmit={(
          values: FormPayload,
          { setSubmitting }: FormikHelpers<FormPayload>
        ) => {
          fetch(`/api/${id}`, {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              message: values.message,
              names: values.guests.map((g) => g.name),
              attendance: values.guests.map((g) => parseInt(g.attendance)),
              dinner: values.guests.map((g) =>
                g.dinner === "" ? 3 : parseInt(g.dinner)
              ),
            }),
          })
            .then((res) => res.json())
            .then((res) =>
              res === true
                ? toast.success(t("rsvp.success"))
                : toast.error(t("rsvp.error"))
            )
            .then(() => setSubmitting(false));
        }}
      >
        {({ values, errors, touched }) => (
          <Form className="grid grid-cols-1 gap-6">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
              <FieldArray
                name="guests"
                render={() =>
                  values.guests.map((friend, index) => (
                    <PersonForm
                      key={index}
                      index={index}
                      values={values}
                      touched={touched}
                      errors={errors}
                    />
                  ))
                }
              />
            </div>
            <div>
              <label
                htmlFor="message"
                className={[
                  "block",
                  "mb-2",
                  "text-sm",
                  "font-medium",
                  errors.message && touched.message
                    ? "text-red-600"
                    : "text-gray-900",
                ].join(" ")}
              >
                {t("rsvp.message")}
              </label>
              <Field
                as="textarea"
                id="message"
                name="message"
                rows={5}
                className={[
                  errors.message && touched.message
                    ? "bg-red-50"
                    : "bg-gray-50",
                  "border",
                  errors.message && touched.message
                    ? "border-red-500"
                    : "border-gray-300",
                  "text-gray-900",
                  "text-sm",
                  "rounded-lg",
                  "focus:ring-rose-500",
                  "focus:border-rose-500",
                  "block",
                  "w-full",
                  "p-2.5",
                ].join(" ")}
              />
              {errors.message && touched.message ? (
                <div className="mt-2 text-sm text-red-600">
                  {t(errors.message)}
                </div>
              ) : null}
            </div>
            <div className="text-end">
              <button
                type="submit"
                className="text-white bg-rose-700 hover:bg-rose-800 focus:ring-4 focus:outline-none focus:ring-rose-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
              >
                {t("rsvp.submit")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
