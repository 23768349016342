import React, { FC } from "react";
import { Invite } from "./sections/invite";
import { Locale } from "./locale";
import { RSVP } from "./sections/rsvp";
import { Info } from "./sections/info";
import { Schedule } from "./sections/schedule";
import "./i18n";
import { Footer } from "./sections/footer";

export const App: FC = () => {
  return (
    <>
      <Locale />
      <div className="grid lg:grid-cols-2">
        <Invite />
        <div>
          <Info />
          <RSVP />
          <Schedule />
          <Footer />
        </div>
      </div>
    </>
  );
};
