import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { App } from "./App";

import "./main.css";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/:locale/:id?" element={<App />} />
        <Route path="/:id?" element={<App />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
