import React, { FC } from "react";
import { useTranslation } from "react-i18next";

export const Info: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="max-w-3xl w-4/5 mx-auto py-4">
      <div className="flex items-center mb-6">
        <div className="flex-grow bg bg-gray-300 h-0.5"></div>
        <h2
          className="flex-grow-0 mx-5 text uppercase text-3xl lg:text-5xl"
          style={{ fontFamily: "'Playfair Display', serif" }}
        >
          {t("info.title")}
        </h2>
        <div className="flex-grow bg bg-gray-300 h-0.5"></div>
      </div>
      <p className="mb-4">{t("info.intro")}</p>
      <p>
        {t("info.when.label")}: {t("info.when.value")}
      </p>
      <p>
        {t("info.where.label")}:{" "}
        <a
          className="text-rose-700 underline"
          href="https://www.google.com/maps/place/H%C3%B6yti%C3%A4isen+Helmi/@62.8509254,29.7834451,17z/data=!3m1!4b1!4m6!3m5!1s0x469c7df3f4abc279:0xd1a85fdbb8cec8b7!8m2!3d62.8509254!4d29.78602!16s%2Fg%2F11khzgqpqp?entry=ttu"
          target="_blank"
          rel="noreferrer"
        >
          {t("info.where.value")}
        </a>
      </p>
      <p className="mb-4">
        {t("info.parking.label")}: {t("info.parking.value")}
      </p>
      <p className="mb-2">{t("info.accommodation")}</p>
      <p className="mb-2">{t("info.dietary")}</p>
      <p>{t("info.gift")}</p>
    </div>
  );
};
