import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      time: {
        days: "Days",
        hours: "Hours",
        minutes: "Minutes",
        seconds: "Seconds",
      },
      info: {
        title: "Info",
        intro:
          "We're excited to invite you to join us as we begin our journey together. We'd be thrilled to have you there to share in this special moment.",
        when: {
          label: "Date",
          value: "June 15th, 2024",
        },
        where: {
          label: "Address",
          value:
            "Höytiäisen Helmi, Riihilahdentie 7, Katajaranta, Finland, 81120",
        },
        parking: {
          label: "Parking",
          value:
            "You can park your cars at the Matkaparkki located near the venue.",
        },
        accommodation:
          "There is limited accommodation on site, if you would like to stay there please let us know in the optional message below and we will try our best to fit everyone in but cannot guarantee space.",
        dietary:
          "If you have any special dietary requirements please also specify those in the message below.",
        gift: "Your presence is enough of a present, but for those who wish to contribute, please be aware that we would appreciate a donation towards our honeymoon fund instead of wedding gifts. (FI59 1064 3500 1868 66)",
      },
      rsvp: {
        title: "RSVP",
        response:
          "Please make sure your response is submitted before 31st March, even if you can't make it, otherwise we'll have to assume you are not available.",
        success:
          "Thanks, your response has been saved. You can come back and edit this page any time.",
        error: "An error occurred, please try again",
        select: "Please select...",
        name: "Guest #{{index}} name",
        attending: {
          name: "Attendance",
          ceremony: "The ceremony",
          celebration: "The celebration",
          ceremonyAndCelebration: "The ceremony and celebration",
          unable: "Unable to attend",
        },
        dinner: {
          name: "Dinner option",
          chicken: "Chicken",
          tofu: "Tofu",
          none: "None",
        },
        message: "Add an optional message",
        submit: "Submit",
      },
      schedule: {
        title: "Schedule",
        guestsArrive: {
          title: "Guests may begin to arrive",
          description:
            "Guests are welcome to arrive and mingle before the ceremony starts. We hope to have the ceremony outside, so dress accordingly! Please let us know if you need to arrive earlier.",
        },
        ceremonyBegins: {
          title: "Ceremony begins",
          description:
            "Led by our friend Noora, the heartfelt ceremony commences, exchanging of vows and celebrating love in front of our family and friends. The official part of the ceremony has been organised at the magistrate the previous day.",
        },
        dinnerServed: {
          title: "Dinner is served",
          description:
            "Delicious cuisine is served, inviting guests to savor a memorable meal together.",
        },
        firstDance: {
          title: "First dance",
          description:
            "The newlyweds take the floor for their first dance. After the first dance the coordinated part of the evening ends and we commence a more relaxed form of celebrating.",
        },
      },
      validation: {
        required: "This value is required",
      },
    },
  },
  fi: {
    translation: {
      time: {
        days: "Päivää",
        hours: "Tuntia",
        minutes: "Minuuttia",
        seconds: "Sekuntia",
      },
      info: {
        title: "Info",
        intro:
          "Toivotamme sinut sydämellisesti tervetulleeksi todistamaan kun aloitamme yhteisen matkamme avioparina. Meille olisi tärkeää jos voisit jakaa tämän erityisen hetken kanssamme.",
        when: {
          label: "Päivämäärä",
          value: "Kesäkuu 15, 2024",
        },
        where: {
          label: "Osoite",
          value:
            "Höytiäisen Helmi, Riihilahdentie 7, Katajaranta, Suomi, 81120",
        },
        parking: {
          label: "Pysäköinti",
          value:
            "Autot voi pysäköidä alueen läheisyydestä löytyvän matkaparkin parkkipaikalle.",
        },
        accommodation:
          "Tilaa majoittumiselle paikan päällä on rajallisesti. Jos haluat yöpyä juhlapaikalla, ilmoita siitä meille alla olevassa viestikentässä. Yritämme parhaamme mukaan saada kaikki halukkaat mahtumaan, mutta emme voi taata yöpaikkaa.",
        dietary:
          "Jos sinulla on erityisruokavalioita tai allergioita ilmoita myös niistä alla olevassa viestissä.",
        gift: "Paras lahja meille on se, että pääsette paikalle. Halutessanne antaa erillisen lahjan voitte fyysisten lahjojen sijaan osallistua häämatkakassaamme. (FI59 1064 3500 1868 66)",
      },
      rsvp: {
        title: "RSVP",
        response:
          "Varmistathan että vastauksesi on lähetetty ennen 31.3.2024. Ilmoitathan lomakkeen kautta myös jos et pysty osallistumaan.",
        success:
          "Kiitos, vastauksesi on tallennettu. Voit palata ja muokata tätä sivua milloin tahansa.",
        error: "An error occurred, please try again",
        select: "Valitse...",
        name: "Vieraan #{{index}} nimi",
        attending: {
          name: "Osallistuminen",
          ceremony: "Seremonia",
          celebration: "Juhla",
          ceremonyAndCelebration: "Seremonia ja juhla",
          unable: "En pääse paikalle",
        },
        dinner: {
          name: "Illallisvalinta",
          chicken: "Kana",
          tofu: "Tofu",
          none: "Ei mitään",
        },
        message: "Lisää valinnainen viesti",
        submit: "Lähetä",
      },
      schedule: {
        title: "Aikataulu",
        guestsArrive: {
          title: "Vieraiden saapuminen",
          description:
            "Vieraat ovat tervetulleita saapumaan ja seurustelemaan ennen seremonian alkua. Toiveenamme on pitää seremonia ulkona joten huomioithan tämän pukeutumisessasi. Kerrothan meille myös etukäteen jos sinun tulee saapua paikalle ennen kahta.",
        },
        ceremonyBegins: {
          title: "Seremonia alkaa",
          description:
            "Ystävämme Nooran johdolla aloitamme lämminhenkisen seremonian jossa vaihdamme lupauksia ja juhlimme rakkautta perheemme ja läheistemme kanssa. Virallinen osuus vihkimisestä on järjestetty maistraatissa edellisenä päivänä.",
        },
        dinnerServed: {
          title: "Illallistarjoilu",
          description:
            "Vieraat kutsutaan pöytiin kun herkullinen ruoka laitetaan tarjolle.",
        },
        firstDance: {
          title: "Ensimmäinen tanssi",
          description:
            "Tuore aviopari siirtyy tanssilattialle ja jakaa ensimmäisen yhteisen tanssinsa avioparina. Tämän jälkeen juhlien ohjelmallinen osuus päättyy ja siirrymme hieman rennompaan juhlintaan.",
        },
      },
      validation: {
        required: "Tämä arvo vaaditaan",
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "fi",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
