import React, { FC } from "react";

export const Footer: FC = () => {
  return (
    <div className="max-w-3xl w-3/5 mx-auto py-36 relative flex items-center">
      <div className="flex-grow bg bg-gray-300 h-0.5"></div>
      <div
        className="opacity-30 text-9xl text-center flex items-center justify-center"
        style={{ fontFamily: '"Dancing Script", serif' }}
      >
        <span className="text-rose-700">D</span>
        <span className="text-2xl">&amp;</span>
        <span className="text-rose-700">S</span>
      </div>
      <div className="flex-grow bg bg-gray-300 h-0.5"></div>
    </div>
  );
};
