import { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export const Locale: FC = () => {
  const navigate = useNavigate();
  const { locale, id } = useParams<{ locale?: string; id?: string }>();
  const {
    i18n: { isInitialized, changeLanguage },
  } = useTranslation();

  const setLocale = useCallback(
    (newLocale: string) => {
      changeLanguage(newLocale);
      if (newLocale !== locale) {
        navigate(id ? `/${newLocale}/${id}` : `/${newLocale}`, {
          replace: true,
        });
      }
    },
    [changeLanguage, locale, id, navigate]
  );

  useEffect(() => {
    console.log(isInitialized, locale);
    if (locale && isInitialized) {
      setTimeout(() => setLocale(locale), 200);
    }
  }, [locale, isInitialized, setLocale]);

  return (
    <div className="fixed flex gap-x-2 top-8 end-8 z-50">
      <img
        src="/images/finland.png"
        className="cursor-pointer overflow-hidden rounded shadow-md shadow-black/50"
        onClick={() => setLocale("fi")}
        alt="Suomeksi"
        width="32"
      />
      <img
        src="/images/united-kingdom.png"
        className="cursor-pointer overflow-hidden rounded shadow-md shadow-black/50"
        onClick={() => setLocale("en")}
        alt="English"
        width="32"
      />
    </div>
  );
};
