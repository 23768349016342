import { FC } from "react";
import { useTranslation } from "react-i18next";

export const Schedule: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="max-w-3xl w-4/5 mx-auto py-4">
      <div className="flex items-center mb-6">
        <div className="flex-grow bg bg-gray-300 h-0.5"></div>
        <h2
          className="flex-grow-0 mx-5 text uppercase text-3xl lg:text-5xl"
          style={{ fontFamily: "'Playfair Display', serif" }}
        >
          {t("schedule.title")}
        </h2>
        <div className="flex-grow bg bg-gray-300 h-0.5"></div>
      </div>

      <div className="">
        <div className="relative pl-8 sm:pl-32 py-6 group">
          <div className="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-rose-700 after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
            <time className="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-white bg-rose-700 rounded-full">
              14:00
            </time>
            <div className="text-xl font-bold text-slate-900">
              {t("schedule.guestsArrive.title")}
            </div>
          </div>
          <div className="text-slate-500">
            {t("schedule.guestsArrive.description")}
          </div>
        </div>

        <div className="relative pl-8 sm:pl-32 py-6 group">
          <div className="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-rose-700 after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
            <time className="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-white bg-rose-700 rounded-full">
              15:00
            </time>
            <div className="text-xl font-bold text-slate-900">
              {t("schedule.ceremonyBegins.title")}
            </div>
          </div>
          <div className="text-slate-500">
            {t("schedule.ceremonyBegins.description")}
          </div>
        </div>

        <div className="relative pl-8 sm:pl-32 py-6 group">
          <div className="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-rose-700 after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
            <time className="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-white bg-rose-700 rounded-full">
              16:00
            </time>
            <div className="text-xl font-bold text-slate-900">
              {t("schedule.dinnerServed.title")}
            </div>
          </div>
          <div className="text-slate-500">
            {t("schedule.dinnerServed.description")}
          </div>
        </div>

        <div className="relative pl-8 sm:pl-32 py-6 group">
          <div className="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-rose-700 after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
            <time className="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-white bg-rose-700 rounded-full">
              20:00
            </time>
            <div className="text-xl font-bold text-slate-900">
              {t("schedule.firstDance.title")}
            </div>
          </div>
          <div className="text-slate-500">
            {t("schedule.firstDance.description")}
          </div>
        </div>
      </div>
    </div>
  );
};
